import styled from 'styled-components'
import { Flex, Button } from '@renderbus/common/components'
import { Media, typography, spacing } from '@renderbus/common/theme'
import Decoration from '../images/2020-1111/decoration.png'

export const BannerContainer = styled(Flex)`
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
  ${Media.lessThan(Media.small)} {
    padding-bottom: 190px;
    height: 400px;
  }
`
export const SubTitle = styled.p`
  background-image: linear-gradient(to right, #f3dd38, #e2f95a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: ${typography.title};
  position: relative;
  margin: 50px auto 0 auto;
  :before {
    content: '';
    background: url(${Decoration});
    background-size: 40px 40px;
    position: absolute;
    height: 40px;
    width: 40px;
    left: -50px;
    top: ${spacing.small};
    ${Media.lessThan(Media.small)} {
      background-size: ${spacing.large};
      height: ${spacing.large};
      width: ${spacing.large};
      left: -40px;
      top: 0;
    }
  }
  :after {
    content: '';
    background: url(${Decoration});
    background-size: 40px 40px;
    position: absolute;
    height: 40px;
    width: 40px;
    right: -50px;
    top: 10px;
    ${Media.lessThan(Media.small)} {
      background-size: ${spacing.large};
      height: ${spacing.large};
      width: ${spacing.large};
      right: -40px;
      top: 0;
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 250px;
    font-size: ${typography.h3};
  }
`
export const ActivityTime = styled.p`
  background-image: linear-gradient(to right, #18ecff, #23ff98);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: ${typography.h3};
  margin-top: ${spacing.large};
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h3};
  }
`
export const Img = styled.img`
  ${Media.lessThan(Media.small)} {
    width: 95%;
  }
`
export const BannerButton = styled(Button)`
  width: 200px;
  height: 50px;
  line-height: 50px;
  border-radius: 1.5rem;
  text-align: center;
  font-size: 20px;
  margin-top: ${spacing.small};
  background: linear-gradient(to right, #f3dd38, #e2f95a);
  color: #000027;
  ${Media.lessThan(Media.small)} {
    width: 160px;
    height: 40px;
    line-height: 40px;
    font-size: ${typography.h4};
    overflow: initial !important;
  }
`
export const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
`
export const Section = styled(Flex)`
  justify-content: space-between;
  width: 1000px;
  margin-bottom: 90px;
  flex-wrap: wrap;
  ${Media.lessThan(Media.small)} {
    width: 96%;
    margin-bottom: 45px;
  }
`
export const Item = styled(Flex)`
  flex-shrink: 0;
  flex-direction: column;
  color: white;
  position: relative;
  img {
    width: 120px;
    padding: ${spacing.base} 0 ${spacing.large};
    margin: 0 ${spacing.small};
  }
  :not(:last-of-type):after {
    content: '';
    position: absolute;
    right: -155px;
    top: 40%;
    height: 1px;
    width: 165px;
    border: 1px dashed #007e44;
  }
  ${Media.lessThan(Media.small)} {
    img {
      width: 90px;
      padding: ${spacing.small} 0;
    }
    width: 40%;
    :nth-child(1),
    :nth-child(2) {
      margin-bottom: 60px;
    }
    :not(:last-of-type)::after {
      width: 100px;
      right: -85px;
      top: 40%;
    }
    :nth-last-child()::after {
      display: none;
    }
    :nth-child(2)::after {
      width: 1px;
      height: 60px;
      top: 135px;
      left: 50%;
    }
  }
`
export const RulesContainer = styled.ol`
  margin: 0;
  width: 1000px;
  color: white;
  ${Media.lessThan(Media.small)} {
    width: 100%;
  }
`
export const SectionTitle = styled.img`
  margin-bottom: 50px;
  ${Media.lessThan(Media.small)} {
    width: 80%;
    margin-bottom: ${spacing.base};
  }
`
export const CouponCard = styled(Flex)`
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: ${spacing.small} ${spacing.small};
  background: linear-gradient(#2815a3, #190980);
  width: 280px;
  height: 360px;
  color: white;
  font-size: ${typography.h3};
  padding-top: ${spacing.small};
  ${Media.lessThan(Media.small)} {
    width: 160px;
    height: 205px;
    font-size: 15px;
    margin-bottom: ${spacing.base};
  }
`
export const Badge = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 25%;
`
export const PriceWrapper = styled(Flex)`
  border-top: 1px dashed #00ff89;
  border-bottom: 1px dashed #00ff89;
  color: #00ff89;
  width: 80%;
  height: 40%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${spacing.base};
  p {
    font-size: ${typography.title};
    margin: ${spacing.small} auto;
  }
  ${Media.lessThan(Media.small)} {
    margin-bottom: ${spacing.small};
    width: 70%;
    p {
      font-size: 15px;
    }
  }
`
export const PriceContainer = styled.div`
  ${Media.lessThan(Media.small)} {
    margin: -10px auto;
  }
`
export const Price = styled.span`
  font-size: ${typography.h1};
`
export const CardButton = styled(BannerButton)`
  width: 180px;
  ${Media.lessThan(Media.small)} {
    width: 110px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    padding: 0;
  }
`
